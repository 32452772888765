import { Box, Button, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { VehicleContext } from '../../App'

function Home() {

    const navigate = useNavigate()
    const location = useLocation();
    let vehicleNo;
    const {vehicles} = useContext(VehicleContext)

    useEffect(() => {

        if(location.search.length > 0) {
            vehicleNo = location.search.slice(1).toLowerCase()
            const qrVehicle = vehicles.filter((vehicle)=> vehicle.regNumber.toLowerCase() === vehicleNo)
            navigate(`/vehicleDetails/${qrVehicle[0].regNumber}`, {state: {
                id: qrVehicle[0].id,
                driverName: qrVehicle[0].driverName, 
                driverPhoneNumber: qrVehicle[0].driverPhoneNumber
              }})
        }
    },[])
    
    
  return (
    <Box className={'containers'} gap='20px'>
        <Typography variant='h3'>HOME</Typography>
        <Button variant='contained' color="primary"
            onClick={()=>navigate('/register')}
        >
            Register Vehicle
        </Button>
        <Button variant='contained' color="secondary"
            onClick={()=>navigate('/allVehicles')}
        >
            View Vehicles
        </Button>
    </Box>
  )
}

export default Home