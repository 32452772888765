import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { VehicleContext } from '../../App'
import { Formik } from 'formik';
import { generateClient} from 'aws-amplify/api';

const client = generateClient();

const vehicleNoRegex = /^[uU][a-zA-Z]{2}\d{3}[a-zA-Z]$/
const phoneNumberRegex = /^0\d{9}$/

function RegisterVehicle() {
 
    const {userId, vehicles, setVehicles} = useContext(VehicleContext)
 
    const currentVehicles = [...vehicles]

    const navigate = useNavigate()

    const registerVehicle = async(values) =>{

        const userIdString = userId ? `userID: "${userId}",`: ""
        const createVehicleType = userId ? `createVehicle`: "createPublicVehicle"
        const {regNumber, driverName, driverPhoneNumber} = values
        try{
          // const registeredVehicle = await client.graphql({
          //   query: `mutation MyMutation {
          //     ${createVehicleType}(input: {
          //       ${userIdString}
          //       regNumber: "${regNumber}", 
          //       driverName: "${driverName}", 
          //       driverPhoneNumber: "${driverPhoneNumber}"
          //     }) {
          //       id
          //     }
          //   }
          //   `
          // })     
          const registeredVehicle = await client.graphql({
            query: `mutation MyMutation {
              createPublicVehicle(input: {
                driverName: "${driverName}", 
                driverPhoneNumber: "${driverPhoneNumber}", 
                regNumber: "${regNumber}"
              }) {
                id
              }
            }
            `
          })     
          if(registeredVehicle){
            setVehicles(()=> {
              currentVehicles.push({
                id: userId 
                  ? registeredVehicle.data?.createVehicle?.id
                  : registeredVehicle.data?.createPublicVehicle?.id,
                regNumber: regNumber,
                driverName: driverName,
                driverPhoneNumber: driverPhoneNumber
              })
              return currentVehicles
            })
            navigate(`/vehicleDetails/${regNumber}`, {state: {
              driverName, driverPhoneNumber
            }})
          }
        }
        catch(e){
          console.log('Error Registering Vehicle', e)
        }
      }
    
    const register = (values, { setSubmitting })=>{
        setTimeout(()=>{setSubmitting(false)}, 1000);
        registerVehicle(values)
              
    }
    const initialValues ={
        regNumber: "",
        driverName: "",
        driverPhoneNumber: "",
    }
    
  return (
    <Box className={'containers'}
    >
        <Typography 
            variant="h3"
            > Register Vehicle</Typography>
        <Formik
              onSubmit={register}
              initialValues={initialValues}
              validate={values => {
                const errors = {};
                if (!values.driverPhoneNumber) {
                  errors.driverPhoneNumber = 'Required';
                }else if (
                  !phoneNumberRegex.test(values.driverPhoneNumber)
                ) {
                  errors.driverPhoneNumber = 'Invalid Phone Number';
                }
                if (!values.driverName) {
                  errors.driverName = 'Required';}
                if (!values.regNumber) {
                  errors.regNumber = 'Required';
                } else if (
                  !vehicleNoRegex.test(values.regNumber)
                ) {
                  errors.regNumber = 'Invalid Number Plate';
                }
                return errors;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box className="formBox">
                      <TextField
                          type="text"
                          label="Number plate"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.regNumber}
                          name="regNumber"
                      />
                      <p className="helperText">
                        {errors.regNumber && touched.regNumber && errors.regNumber}
                      </p>
                    </Box>
                    <Box className="formBox">
                      <TextField
                          type="text"
                          label={`Driver's Name`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.driverName}
                          name="driverName"
                      />
                      <p className="helperText">
                        {errors.driverName && touched.driverName && errors.driverName}
                      </p>
                    </Box>
                    <Box className="formBox">
                      <TextField
                          type="tel"
                          label={`Driver's Phone Number`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.driverPhoneNumber}
                          name="driverPhoneNumber"
                      />
                      <p className="helperText">
                        {errors.driverPhoneNumber && touched.driverPhoneNumber && errors.driverPhoneNumber}
                      </p>
                    </Box>
                    <Box display="flex" gap="20px">
                        <Button type='submit' 
                          color='primary' disabled={isSubmitting}
                          variant='contained'>Register</Button>
                        <Button color='secondary' variant='outlined'
                            onClick={()=> navigate('/allVehicles')}
                        >Back</Button>
                    </Box>
                </form>
              )
              }
        </Formik>
    </Box>
  )
}

export default RegisterVehicle