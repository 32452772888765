import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import { Route, Routes} from 'react-router-dom';
import Home from './scenes/Home/Home';
import RegisterVehicle from './scenes/RegisterVehicle/RegisterVehicle';
import VehicleDetails from './scenes/VehicleDetails/VehicleDetails';
import { createContext, useEffect, useState } from 'react';
import AllVehicles from './scenes/AllVehicles/AllVehicles';
import { Box, Typography } from '@mui/material';
import { generateClient } from 'aws-amplify/api';
Amplify.configure(awsExports);

const VehicleContext = createContext()

function App({ signOut, user }) {

  const [vehicles, setVehicles] = useState()
  const userEmail = user?.signInDetails?.loginId
  const userId = user?.userId
  const client = generateClient();

  useEffect(() =>{

    const getUser = async() =>{
      try{
        const userDetails = await client.graphql({
          query: `query MyQuery {
            getUser(id: "${userId}") {
              id
            }
          }
          `
        })     
        if(!userDetails.data.getUser){
          createUser()
        }else {
          listVehicles()
        }
      }
      catch(e){
        console.log('Error getting user', e)
      }
    }

    const createUser = async() =>{
      try{
        const createdUser = await client.graphql({
          query: `mutation MyMutation {
            createUser(input: {
              id: "${userId}", 
              email: "${userEmail}", 
              userType: "lite"
            }) {
              id
            }
          }          
          `
        })     
        if(createdUser){
          setVehicles([]);
        }
      }
      catch(e){
        console.log('Error getting user', e)
      }
    }

    const listVehicles = async() =>{
      try{
        const allVehicles = await client.graphql({
          query: `query MyQuery {
            listVehicles(limit: 1000, filter: {userID: {eq: "${userId}"}}) {
              items {
                id
                driverPhoneNumber
                driverName
                regNumber
              }
            }
          }
          `
        })     
        if(allVehicles.data.listVehicles.items.length === 0){
          setVehicles([])
        }else{
          setVehicles(allVehicles.data.listVehicles.items);
        }
      }
      catch(e){
        console.log('Error getting vehicles', e)
      }
    }

    const getPublicVehicles = async() =>{
      try{
        const allVehicles = await client.graphql({
          query: `query MyQuery {
            listPublicVehicles(limit: 1000) {
              items {
                id
                driverPhoneNumber
                driverName
                regNumber
              }
            }
          }
          `
        })     
        if(allVehicles.data.listPublicVehicles.items.length === 0){
          setVehicles([])
        }else{
          setVehicles(allVehicles.data.listPublicVehicles.items);
        }
      }
      catch(e){
        console.log('Error getting vehicles', e)
      }
    }

    user && getUser()
    !user && getPublicVehicles()

  },[]);
  
  return (
    <Box
    sx={{
      backgroundColor: 'white',
      p: '20px',
      minWidth: {xs: '100vw', md: '700px'},
      minHeight: '80vh',
    }}
    >
      {!vehicles && <Typography variant="h2">Loading...</Typography>}
      {vehicles && <VehicleContext.Provider value={{vehicles, setVehicles, signOut, userId}}>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/register" element={<RegisterVehicle/>}/>
          <Route path="/allVehicles" element={<AllVehicles/>}/>
          <Route path="/vehicleDetails/:regNo"
            element={<VehicleDetails/>}/>
        </Routes>
      </VehicleContext.Provider>}
    </Box>
  ); 
}

export default App;
export {VehicleContext}
