import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Box, Typography } from '@mui/material';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { BrowserRouter } from 'react-router-dom';
Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Box className="containers"
    sx={{
      backgroundColor: '#03155C',
      minHeight: '100vh',
      justifyContent: 'flex-start',
    }}
    >
      <Typography variant="h5" p="20px"
        color="white"
      >
        Vehicle Managment Portal
      </Typography>
      <App />
    </Box>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
