import { Box, Button, IconButton, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { useContext } from 'react'
import { VehicleContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function AllVehicles() {

    const navigate = useNavigate()
    const {vehicles} = useContext(VehicleContext)
    const columns = [
        {
            field: 'regNumber',
            headerName: 'Reg. No.',
            width: 150
        },
        {
            field: 'driverName',
            headerName: `Driver's Name`,
            width: 200
        },
        {
            field: 'driverPhoneNumber',
            headerName: `Driver's Phone No.`,
            width: 200
        },
        {
            field: 'view',
            width: 80,
            headerName: '',
            renderCell: (params)=>{
                return(
                    <Button variant="outlined" color="success"
                        size="small"
                        onClick={()=>{
                            navigate(`/vehicleDetails/${params.row.regNumber}`, {state: {
                                id: params.row.id,
                                driverName: params.row.driverName, 
                                driverPhoneNumber: params.row.driverPhoneNumber
                            }})
                        }}
                    >View</Button>
                )
            }
        },
    ]

  return (
    <Box className="containers" gap="20px" maxWidth={'100vw'}
        sx={{
            overflowX: 'scroll',
            pl: '10px',
            pr: '10px',
        }}
    >
        <Typography variant="h3">All Vehicles</Typography>
        <DataGrid rows={vehicles} columns={columns}
            hideFooter={true}
            sx={{
                width: "100%",
                '@media (max-width: 900px)': {
                    overflowX: 'auto',
                    // width: '600px'
                },
                
            }}
        />
        <Box display="flex" gap="20px">
            <Button type='submit' color='primary' variant='contained'
                onClick={()=> navigate('/register')}
            >Add Vehicle</Button>
            <Button color='secondary' variant='outlined'
                onClick={()=> navigate('/')}
            >Back</Button>
        </Box>
    </Box>
  )
}

export default AllVehicles