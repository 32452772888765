import { Box, Button, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { exportComponentAsPNG } from 'react-component-export-image';
import QRCode from 'react-qr-code';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { generateClient} from 'aws-amplify/api';
import { VehicleContext } from '../../App';

const client = generateClient();

function VehicleDetails() {

    const {regNo} = useParams()
    const navigate = useNavigate()
    const qref = useRef()
    const [printWidth, setPrintWidth] = useState('250px')
    const {state} = useLocation()
    const {userId, vehicles, setVehicles} = useContext(VehicleContext)

    useEffect(() =>{
        printWidth === '1200px' && exportComponentAsPNG(qref)
        return (setPrintWidth('250px'))
    },[printWidth])

    const deleteVehicle = async(values) =>{

        const userIdString = userId ? `userID: "${userId}",`: ""
        const deleteVehicleType = userId ? `deleteVehicle`: "deletePublicVehicle"
        try{
          // const registeredVehicle = await client.graphql({
          //   query: `mutation MyMutation {
          //     ${deleteVehicleType}(input: {
          //       ${userIdString}
          //       regNumber: "${regNumber}", 
          //       driverName: "${driverName}", 
          //       driverPhoneNumber: "${driverPhoneNumber}"
          //     }) {
          //       id
          //     }
          //   }
          //   `
          // })     
          const deletedVehicle = await client.graphql({
            query: `mutation MyMutation {
              deletePublicVehicle(input: {
                id: "${state.id}",
              }) {
                id
              }
            }
            `
          })     
          if(deletedVehicle){
            setVehicles(()=> {
              return vehicles.filter((vehicle) => vehicle.id !== state.id)
            })
            setTimeout(()=>{navigate(`/allVehicles/`)},1000)
          }
        }
        catch(e){
          console.log('Error Deleting Vehicle', e)
        }
      } 
    
  return (
    <Box className={'containers'} gap="10px">
        <Typography variant="h3">Vehicle Details</Typography>
        <Typography>Registration Number: {regNo}</Typography>
        <Typography>Driver Name: {state.driverName}</Typography>
        <Typography>Driver Phone Number: {state.driverPhoneNumber}</Typography>
        <Box p="20px" ref={qref} width={printWidth}>
            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={`https://qrdemo.256web.design?${regNo}`}
                viewBox={`0 0 256 256`}
            />
        </Box>
        <Button variant="contained"
            onClick={() => setPrintWidth('1200px')}
        >Export as PNG</Button>
        <Box display="flex" gap="20px">
            <Button color='secondary' variant='outlined'
                onClick={()=> navigate('/allVehicles')}
            >Back</Button>
            <Button color='error' variant='outlined'
                onClick={deleteVehicle}
            >DELETE VEHICLE</Button>
        </Box>
    </Box>
  )
}

export default VehicleDetails